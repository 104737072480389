<script>
  export let timelineData;

  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition';
  import { browser, building, dev, version } from '$app/environment';

  import { getAge } from '$lib/utils/getAge';

  const ageExpectancy = 82;
  const birthday = new Date(1982, 4, 24);

  const currentWeekNum = new Date().getWeek();
  // const weeksInYear = Array.from(Array(new Date().getIsoWeeks()), (e, i) => i + 1);
  // const timeline = Array.from({ length: ageExpectancy }).map(() => weeksInYear);

  const defaultFocusedWeek = () => ({ yi: getAge(birthday), week: currentWeekNum });

  let focusedWeek = defaultFocusedWeek();

  const handleDisplayTheDay = (yi, week) => (focusedWeek = { yi, week });

  const ordinal_suffix_of = (i) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = i % 100;
    return `${i}<sup>${s[(v - 20) % 10] || s[v] || s[0]}</sup>`;
  };

  const getSeason = (weekNumber) => {
    let seasons = [
      { season: 'Winter', startWeek: 50, endWeek: 12, icon: '❄️' },
      { season: 'Spring', startWeek: 13, endWeek: 25, icon: '🌱' },
      { season: 'Summer', startWeek: 26, endWeek: 38, icon: '🌼' },
      { season: 'Fall', startWeek: 39, endWeek: 49, icon: '🍁' }
    ];

    let matchedSeason = seasons.find((season) => {
      if (season.startWeek <= season.endWeek) {
        return weekNumber >= season.startWeek && weekNumber <= season.endWeek;
      } else {
        return weekNumber >= season.startWeek || weekNumber <= season.endWeek;
      }
    });

    return matchedSeason ? `${matchedSeason.icon} ${matchedSeason.season}` : null;
  };

  const getLifeStage = (age) => {
    let lifeStages = [
      { stage: 'Infant', startAge: 0, endAge: 1, icon: '👶' },
      { stage: 'Toddler', startAge: 1, endAge: 3, icon: '👦' },
      { stage: 'Preschooler', startAge: 3, endAge: 5, icon: '🧒' },
      { stage: 'Child', startAge: 6, endAge: 12, icon: '👧' },
      { stage: 'Teenager', startAge: 13, endAge: 19, icon: '🧔' },
      { stage: 'Young Adult', startAge: 20, endAge: 29, icon: '🧑' },
      { stage: 'Adult', startAge: 30, endAge: 64, icon: '🧔' },
      { stage: 'Senior', startAge: 65, endAge: 100, icon: '👴' }
    ];

    let matchedLifeStage = lifeStages.find((lifeStage) => {
      return age >= lifeStage.startAge && age <= lifeStage.endAge;
    });

    return matchedLifeStage ? `${matchedLifeStage.icon} ${matchedLifeStage.stage}` : null;
  };

  // Return the four-digit year of a given year relative to birthday
  // const getYearFromYearCount = (yearCount) => birthday.getUTCFullYear() + yearCount;

  // Returns either 52 or 53 depending on if the year is a leap year
  const getWeeksFromYear = (date) => {
    const year = date.getUTCFullYear();
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    const weeksInYear = isLeapYear ? 53 : 52;
    return weeksInYear;
  };

  // Function to get an array of arrays representing weeks in each year
  const getTimeLine = (startYear, endYear) => {
    const result = [];
    for (let year = startYear; year <= endYear; year++) {
      const weeksInYear = getWeeksFromYear(new Date(year, 0, 1));
      const weeksArray = Array.from({ length: weeksInYear }, (_, i) => i + 1);
      result.push(weeksArray);
    }
    return result;
  };

  const timeline = getTimeLine(birthday.getUTCFullYear(), birthday.getUTCFullYear() + ageExpectancy - 1);

  $: ageGroup = getLifeStage(focusedWeek.yi);

  // $: timelineData.map((event) => {
  //   const eventDate = new Date(event.date);
  //   const eventYearWeek = `y${eventDate.getFullYear() - birthday.getFullYear() === 0 ? 1 : eventDate.getFullYear() - birthday.getFullYear()}w${eventDate.getWeek()}`;
  //   if (browser) {
  //     setTimeout(() => {
  //       document.getElementById(eventYearWeek).style.backgroundColor = event.color;
  //       document.getElementById(eventYearWeek).title = event.title;
  //     }, 1000);
  //   };
  // });
</script>

<div on:mouseleave={() => (focusedWeek = defaultFocusedWeek())} class="relative bpk-life-calendar">
  {#each timeline as year, yi}
    <div class="bpk-life-calendar-year year-{yi + 1}">
      {#each year as week}
        <!-- <a href="/life/{yi + 1}/{week}"> -->
          <span
            on:focus={() => handleDisplayTheDay(yi + 1, week)}
            on:mouseover={() => handleDisplayTheDay(yi + 1, week)}
            id="y{yi + 1}w{week}"
            class={`bpk-life-calendar-week hover:bg-[#ff2e85]
              ${yi + 1 <= getAge(birthday) && 'lived'}
              ${yi + 1 === getAge(birthday) && week === currentWeekNum && 'current-week rounded-full'}
              ${yi + 1 === getAge(birthday) && week > currentWeekNum && 'remaining'}
            `}
            style={yi === 0 && week < 21 ? 'background-color: #fff' : ''}
          />
        <!-- </a> -->
      {/each}
      {#if year.length === 52}
        <span
          class="w-[6px] h-[6px] mb-[1px]"
          style="background-color: {yi + 2 > getAge(birthday) ? '#f6f6f7' : '#f6f6f7'}"
        />
      {/if}
    </div>
  {/each}
  {#if focusedWeek}
    <div class="bpk-life-calendar-week-events flex justify-between mb-3 mt-4">
      <p class="color-[#374453]">
        {@html ordinal_suffix_of(focusedWeek.week)} week of year <span class="font-semibold">{focusedWeek.yi}</span>
      </p>
      <div class="space-x-2">
        {#if ageGroup}
          <span transition:slide class="text-sm">{ageGroup}</span>
        {/if}
        <span class="text-sm">{getSeason(focusedWeek.week)}</span>
      </div>
    </div>
  {/if}
</div>
