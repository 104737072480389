/**
 * Important events that have happend in my life that I want to highlight and
 * share with the world.
 *
 * Date Format: date: 'YYYY-MM-DDTHH:mm:ss.sssZ'
 *
 */
export const data = [
  // { guid: '4b79fb5bfd50', title: 'First time visiting San Francisco', date: '2000-08-16', location: 'San Francisco, CA', lng: '', lat: '', color: '#336699', },
  { guid: 'cf50012c16bf', title: 'Moved to San Francisco', date: '2003-10-15', location: 'San Francisco, CA', lng: '', lat: '', color: '#336699', },
  // { guid: '0979e58679e5', title: 'Evaleigh was born', date: '2007-12-11', location: 'Berkeley, CA', lng: '', lat: '', color: '#639', },
  // { guid: '10fe96cbeb9a', title: 'Benett was born', date: '2009-06-14', location: 'Walnut Creek, CA', lng: '', lat: '', color: '#336699', },
  { guid: '7dce4a32ccd1', title: 'My first tweet', date: '2008-04-17', location: '', lng: '', lat: '', color: '#336699', },
  { guid: '01a23adefcf3', title: 'My first conference presenation (DjangoCon Chicago)', date: '2013-09-05', location: 'Chicago, IL', lng: '', lat: '', color: '#336699', },
  { guid: 'ce0f9539583f', title: 'Moved back to Midwest', date: '2012-06-17', location: 'Oakland, CA', lng: '', lat: '', color: '#624', },
  { guid: 'ce0f9324823f', title: 'Started working at Landus', date: '2022-04-01', location: 'East Troy, WI', lng: '', lat: '', color: '#824', },
];
