let ceil = Math.ceil;

/**
 * String.capitalize - Capitalize the first character of a string.
 *
 * Example:
 *   const foo = "this is a headline"
 *   foo.capitalize()
 *   This is a headline
 */
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

/**
 * Array.chunk() - Break an array down into chunks
 *
 * Example:
 *   const foo = [1,2,3,4,5,6,7,8,9,10]
 *   foo.chunk(3)
 *   [[1,2,3], [4,5,6], [7,8,9], [10]
 */
if (!Array.prototype.chunk) {
  Object.defineProperty(Array.prototype, 'chunk', {
    value: function(n) {
      return Array.from(Array(Math.ceil(this.length / n)), (_, i) =>
        this.slice(i * n, i * n + n)
      );
    }
  });
}

/**
 * Date.getWeek() - Returns the ISO week of the date.
 */
Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

/**
 * Date.getWeekYear() - Returns four-digit year of current ISO date.
 */
Date.prototype.getWeekYear = function() {
  var date = new Date(this.getTime());
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  return date.getFullYear();
};

/**
 * Date.getWeekYear() - Returns four-digit year of current ISO date.
 */
Date.prototype.getIsoWeeks = function(y) {
  var d, isLeap;
  d = new Date(y, 0, 1);
  isLeap = new Date(y, 1, 29).getMonth() === 1;
  // check for a Jan 1 that's a Thursday or a leap year that has a
  // Wednesday jan 1. Otherwise it's 52
  return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
};
